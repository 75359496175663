@if (data) {
	<div class="yuno-text-file-container">
		<div class="me-2">
			<span class="flex items-center gap-2 text-lg font-semibold text-yuno-primary">
				{{ title | languageSelect: language }}
			</span>
			<span class="flex pb-2 text-sm font-semibold text-yuno-blue-gray-700">
				{{ data.data?.fileName }}{{ data.data?.ext }}
			</span>

			<span class="flex gap-2 whitespace-nowrap text-sm">
				<div
					class="yuno-text-file-background"
					[ngClass]="{
						'bg-yuno-danger': data.type === 'pdf',
						'bg-yuno-success': data.type === 'image',
						'bg-yuno-warning': data.type === 'zip'
					}">
					<span>{{ data.data?.ext?.substring(1)?.toUpperCase() }}</span>
				</div>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
					class="self-center"
					width="3"
					height="4"
					viewBox="0 0 3 4"
					fill="none">
					<circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
				</svg>
				<span class="font-bold text-yuno-blue-gray-700">
					{{ (data.data?.fileSize || 0) / 1024 / 1000 | number: '1.0-2' }} MB
				</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
					class="self-center"
					width="3"
					height="4"
					viewBox="0 0 3 4"
					fill="none">
					<circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
				</svg>
				<span class="text-xkp-blue-gray-600">
					Uploaded {{ data.updatedAt | date: 'dd-MM-YYYY' }}
				</span>
			</span>
		</div>
		<div class="align-center inline-flex justify-end">
			<a [href]="data.data?.url" target="_blank">
				<button
					class="inline-flex items-center self-center rounded-full bg-yuno-blue-gray-600 p-2 text-center text-sm font-medium text-white transition-colors duration-300 ease-in-out hover:bg-yuno-blue-gray-700 focus:outline-none focus:ring-1"
					type="button">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="currentColor"
						class="h-6 w-6">
						<path
							fill-rule="evenodd"
							d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
							clip-rule="evenodd" />
					</svg>
				</button>
			</a>
		</div>
	</div>
}
