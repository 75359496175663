import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LanguagePipe } from '@yuno/angular/pipes';
import { CdnFile, LanguageStringsModel } from '@yuno/api/interface';

@Component({
	selector: 'yuno-text-file',
	templateUrl: './text-file.component.html',
	styleUrls: ['./text-file.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, LanguagePipe, DatePipe, DecimalPipe]
})
export class TextFileComponent {
	@Input() language?: string;
	@Input() title?: LanguageStringsModel;

	_data: CdnFile;
	@Input() set data(data: CdnFile) {
		this._data = data;
	}

	get data(): CdnFile {
		return this._data;
	}
}
